import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  Phone,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  VideoAndText,
  Image
} from '../../components'

import {
  faFacebookSquare,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.klepschgroup.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.intercable.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.rauchmehl.jpg'
import Claudia from '../../assets/claudia.pichler.png'

const Klepschgroup = () => {
  const links = generateBreadcrumbsLinks('/projekte/klepsch-group', 'Klepsch-Gruppe')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="klepsch-group - Multiportalsystem"
        meta={[
          {
            name: 'description',
            content:
              'Das Multiportalsystem der Klepsch-Gruppe wurde einem Soft-Redesign unterzogen, um dem State-of-the-art des Webs zu folgen. Das Redesign umfasste ein Umgestalten aller Contentelemente, um diese lese-freundlicher zu machen.'
          },
          {
            name: 'keywords',
            content:
              'klepsch holzweg, Projekt holzweg, Klepschgruppe, Webseite holzweg, Klepsch, eZ Publish, ezpublish, eZ-Publish, Salzburg, klepschgroup, plastik'
          },
          {
            property: 'og:description',
            content:
              'Das Multiportalsystem der Klepsch-Gruppe wurde einem Soft-Redesign unterzogen, um dem State-of-the-art des Webs zu folgen. Das Redesign umfasste ein Umgestalten aller Contentelemente, um diese lese-freundlicher zu machen.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.klepschgroup-628ab0284c64e2282018f1fa793d65ad.jpg'
          }
        ]}
      />
    <BannerSmall image={BannerImage} title="Wir sind ein Auto" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Redesign Multiportalsystem<br />der Klepsch-Gruppe
          </div>
        }
        text={
          <span>
            <p>Das Multiportalsystem der Klepsch-Gruppe wurde einem Soft-Redesign unterzogen, um dem <i>State-of-the-art</i> des Webs zu folgen. Schöne, große Bannerleisten mit neuen Slogans wie z.B. "Wir sind ein Auto", "Wir sind eine Badewanne" wecken das Interesse! Auf diese Weise gewinnt man schnell ein Gefühl dafür, wo man auf die Produkte der Klepsch-Gruppe trifft, ohne diese vorher bewusst wahrgenommen zu haben.</p>
            <p>Das Redesign umfasste ein Umgestalten aller Contentelemente, um diese lese-freundlicher zu machen. Eine neuartige Navigation, die über den Burger im rechten oberen Eck aufgerufen wird, erleichtert das schnelle Navigieren auf jedem beliebigen Ausgabegerät (PC, Tablet, Smartphone).</p>
            <p><b>Folgende Portale wurden überarbeitet:</b>
            </p>
          </span>
        }
        buttons={[
          {link: 'https://www.klepschgroup.com/de/startseite', text: 'Website Klepschgroup'},
          {link: 'https://www.senosan.com/de/', text: 'Website Senosan'},
          {link: 'https://www.zellamid.com/de/', text: 'Website Zell Metall'},
          {link: 'https://www.seletec.com/de/', text: 'Website Seletec'},
          {link: 'https://www.senova.com/de/', text: 'Website Senova'},
          {link: 'https://www.senoplast.com/de/', text: 'Website Senoplast'}
        ]}
      />
      <TextBox
        title={
          <div>
            Die richtige Agentur für komplexe Aufgaben!
          </div>
        }
        text={
          <span>
            <i>
              <p>Als wir vor der großen Herausforderung standen, für 13 verschiedene Firmen einen Relaunch der Website zu gestalten, war uns bewusst, dass es für diese Aufgabe eine Agentur mit viel Erfahrung mit Multiportalsystemen, Mehrsprachigkeit und komplexen Aufgabensstellungen braucht.</p>
              <p>Sehr wichtig war uns, die Zugehörigkeit zur Klepsch Group im Design erkennbar zu machen, jedoch trotzdem die Identität der einzelnen Firmen zu bewahren. Die Firma holzweg hat uns hier graphisch bestens unterstützt und gemeinsam haben wir eine entsprechende Lösung gefunden, mit der alle zufrieden sind.</p>
              <p>Weiters wurde die Funktionalität der Websites um ein Vielfaches erhöht – wir bieten jetzt mit der Kontaktsuche einen Service, den Kund:innen sehr schätzen. Hinzu kommt natürlich das Herzstück des Ganzen: der Materialfinder, der unsere Kund:innen technisch berät und unsere gesamte Kompetenz im Kunststoffbereich zeigt. </p>
              <p>Vielen Dank an das gesamte Team bei holzweg – auch für die Ideen und laufenden strategischen Verbesserungsvorschläge.</p>


            </i>
          </span>
        }
      />
      <Image
        image={Claudia}
        disablePaddingbottom={true}
        title=""
      />
      <TextBox title="" text={
        <span><p><b>Mag. Claudia Pichler</b><br />Marketing, Senoplast</p>
        </span>
      }
      />
      <Phone
        title="Zellparts Online"
        description={
          <span>
            <p>Neu hinzugekommen ist u.a. das Portal für Zellparts, ein Unternehmen der Klepsch-Gruppe, das sich hauptsächlich mit der Erzeugung von CNC-Fertigteilen aus Kunststoff beschäftigt.</p>
        </span>
        }
        mainDescription="Responsive Website von Zellparts"
        link="https://www.zellparts.com/de/"
        bgColor="#FFFFFF"
      />
      <VideoAndText
        YTvideo="FeEN_c9Ioek"
        headline="Kurz-Video zur Klepsch Gruppe"
        text=""
      />
      <CallUs contacts={contacts} title="Interesse an einer neuen Website?" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        imageLeft={image4}
        leftLink="/projekte/intercable"
        titleModsLeft={['black']}
        titleLeft={
          <span>
            Intercable Multiportal
            <br /> und Shop
          </span>
        }
        titleRight="Rauchmehl"
        imageRight={image5}
        rightLink="/projekte/rauchmehl"
        titleModsRight={['black']}
      />
      <TextBox
        title="Walk with us on the holzweg"
        text={
          <span>
            <p>
              Ready für uns? Dann kontaktiere uns zu einem unverbindlichen
              Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn
              der holzweg nach Traumjob-Garantie klingt, dann schaue bei den
              Stellenangeboten rein oder besuche uns in den sozialen Medien!
            </p>
            <p>
              <a
                href="https://www.facebook.com/holzwegwebagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#ec6318' }}
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.linkedin.com/company/holzweg-e-commerce-solutions"
                rel="nofollow"
                className="social-icon"
                target="_blank"
                style={{ color: '#f08607' }}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.youtube.com/user/holzwegagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f4a301' }}
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </p>
          </span>
        }
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Klepschgroup
